import type {
  BaseInstance,
  IBaseInstanceConfig,
  InferMonitoring,
} from '@pigello/pigello-matrix';
import { patch } from './api/patch';
import { post } from './api/post';
import type { QueryParams } from './api/types';
import {
  convertResponse,
  toExternalFieldNames,
  toInternalFieldNames,
} from './instanceMapper';
import type { GenericMutateOptions, ListResponse } from './types';
export const mutateGenericInstance = async <Instance extends BaseInstance>({
  config,
  id,
  body,
  options,
}: {
  id: string;
  body: Partial<Instance>;
  config: IBaseInstanceConfig<Instance>;
  options?: GenericMutateOptions;
}) => {
  const url =
    id === '' ? `${config.instanceUrl}/` : `${config.instanceUrl}/${id}/`;
  const bodyToSend = await toExternalFieldNames(
    config,
    body,
    options?.convertRelations
  );

  const res = await patch<Instance>({
    url: url,
    body: bodyToSend,
  });

  return toInternalFieldNames<Instance>(config, res.data);
};

export const postInstancesData = async <Instance extends BaseInstance>({
  config,
  data,
  overrideUrl,
  queryParams,
  skipOrganizationOnExternalFieldNames,
}: {
  config: IBaseInstanceConfig<Instance>;
  data: Instance[];
  overrideUrl?: string;
  queryParams?: QueryParams;
  skipOrganizationOnExternalFieldNames?: string[];
}) => {
  const url = overrideUrl ?? config.listUrl;

  const res = await post<Instance>({
    url: url,
    body: data,
    queryParams,
    skipOrganizationOnExternalFieldNames,
  });

  window.queryClient?.invalidateQueries({
    queryKey: [config.modelName, { modelName: config.modelName }],
  });
  window.queryClient?.invalidateQueries({
    queryKey: [
      config.modelName,
      'list',
      {
        modelName: config.modelName,
      },
    ],
  });

  if (Array.isArray(res.data)) {
    return res.data;
  }

  const convertedData = await convertResponse<Instance>({
    config,
    response: res.data as unknown as ListResponse<Instance>,
  });

  return convertedData as unknown as Instance[] | InferMonitoring<Instance>[];
};
