import { Logger } from '@/logger';
import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import { getConfig } from '@pigello/pigello-matrix';
import chalk from 'chalk';
import * as React from 'react';
const logger = new Logger('useConfig', chalk.blue);

// TODO: Save which configs we've loaded into cache in zustand.:) Return the cached config if it exists.

export function useConfig<
  Instance extends BaseInstance,
  Config extends IBaseInstanceConfig<Instance> = IBaseInstanceConfig<Instance>,
>(modelName: ModelName | null) {
  const [config, setConfig] = React.useState<Config>();
  const [isLoading, setIsLoading] = React.useState(() => {
    if (config !== undefined) return false;

    if (modelName === null) return false;

    return true;
  });

  React.useEffect(() => {
    if (modelName === null || modelName === 'unset') {
      return;
    }
    setIsLoading(true);

    getConfig<Instance, Config>(modelName)
      .then((res) => {
        setConfig(res);
        setIsLoading(false);
      })
      .catch((err) => {
        logger.error(err);
        throw new Error(`Failed to resolve config for modelname: ${modelName}`);
      });
  }, [modelName]);

  return {
    isLoading,
    config,
  };
}

export function useManyConfigs(modelNames?: ModelName[]) {
  const [isLoading, setIsLoading] = React.useState(false);

  const [configs, setConfigs] = React.useState<
    IBaseInstanceConfig<BaseInstance>[]
  >([]);
  React.useEffect(() => {
    if (modelNames === undefined) {
      return;
    }
    if (configs.length === modelNames.length || isLoading) return;

    setIsLoading(true);

    const newConfigs: Array<IBaseInstanceConfig<BaseInstance>> = [];

    let index = 0;

    let got = 0;
    let want = 0;

    const gotConfig = (
      config: IBaseInstanceConfig<BaseInstance>,
      index: number
    ) => {
      got++;
      newConfigs[index] = config;
      if (got === want) {
        setConfigs(newConfigs);
        setIsLoading(false);
      }
    };

    const doGetConfig = (index: number, modelName: ModelName) => {
      want++;

      getConfig(modelName)
        .then((res) => {
          gotConfig(res, index);
        })
        .catch((err) => {
          want--;
          logger.error(err);
          throw new Error(
            `Failed to resolve config for modelname: ${modelName}`
          );
        });
    };

    for (const modelName of modelNames) {
      doGetConfig(index, modelName);
      index++;
    }
  }, [modelNames, isLoading]);

  return {
    isLoading,
    configs,
  };
}
