'use client';
import { raise } from '@/lib/utils';
import type { ErrorResponse } from '@/requests/types';
import { toast, type ExternalToast } from 'sonner';

type ToastType =
  | 'info'
  | 'warning'
  | 'error'
  | 'success'
  | 'promise'
  | 'loading';

type PromiseExternalToast = Omit<ExternalToast, 'description'>;

type PromiseData<
  ToastData = unknown,
  E = ErrorResponse,
> = PromiseExternalToast & {
  loading?: string | React.ReactNode;
  success?:
    | string
    | React.ReactNode
    | ((data: ToastData) => React.ReactNode | string);
  error?: string | React.ReactNode | ((error: E) => React.ReactNode | string);
  description?:
    | string
    | React.ReactNode
    | ((data: unknown) => React.ReactNode | string);
  finally?: () => void | Promise<void>;
};

type PromiseToast<T = unknown, E = ErrorResponse> = {
  type: 'promise';
  promise: Promise<T>;
} & PromiseData<T, E>;

type SonnerToast = {
  type: Exclude<ToastType, 'promise'>;
};

type Toast<T = unknown, E = ErrorResponse> = {
  type: ToastType;
  title?: string;
  message?: React.ReactNode;
} & (PromiseToast<T, E> | SonnerToast);

export const useToast = () => {
  const removeToast = (id: string) => {
    toast.dismiss(id);
  };
  const addToast = <T, E>(toastInfo: Toast<T, E>, data?: ExternalToast) => {
    if (toastInfo.type === 'loading') {
      toast.loading(toastInfo.title, {
        description: toastInfo.message,
        ...data,
      });
    }
    if (toastInfo.type === 'error') {
      toast.error(toastInfo.title, {
        description: toastInfo.message,
        ...data,
      });
    }
    if (toastInfo.type === 'info') {
      toast.info(toastInfo.title, {
        description: toastInfo.message,
        ...data,
      });
    }
    if (toastInfo.type === 'success') {
      toast.success(toastInfo.title, {
        description: toastInfo.message,
        ...data,
      });
    }
    if (toastInfo.type === 'warning') {
      toast.warning(toastInfo.title, {
        description: toastInfo.message,
        ...data,
      });
    }
    if (toastInfo.type === 'promise') {
      toast.promise<T>(
        toastInfo.promise ?? raise('Promise toast must have a promise'),
        {
          ...toastInfo,
          ...data,
        }
      );
    }
  };
  return { addToast, removeToast };
};
