import type { OrderedFields } from '@/hooks/useForm/types';
import { typeGuard } from '@/lib/utils';
import { useFileStore } from '@/store';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import type { useFormContext } from 'react-hook-form';
import SparkMD5 from 'spark-md5';

export function getCustomDisplayName<Instance extends BaseInstance>(
  modelName: ModelName | undefined,
  instance: Instance
): string | null {
  if (!modelName) return null;

  switch (modelName) {
    case 'tenant':
      return typeGuard(instance, 'communicationName')
        ? instance.communicationName
        : null;
    case 'apartment':
      return typeGuard(instance, 'apartmentId')
        ? `${instance.customId ?? 'Saknar objektsnr.'} (${instance.apartmentId ?? 'Saknar lägenhetsnr.'})`
        : null;
    case 'brfcompany':
      return typeGuard(instance, 'company') ? instance.company?.name : null;
    case 'realestate':
      return typeGuard(instance, 'name')
        ? `${instance.name} ${typeGuard(instance, 'popularName') && instance.popularName ? `(${instance.popularName})` : ''}`
        : null;

    case 'debtinvoice':
      return typeGuard(instance, 'debtDate') && typeGuard(instance, 'kind')
        ? `${instance?.debtDate} - ${instance.kind === 0 ? 'Påminnelse' : 'Krav'}`
        : 'Påminnelse';
    default:
      return null;
  }
}

export const toBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const handleFile = async (file: File) => {
  const fileName = file.name;
  const md5 = await computeChecksumMd5(file);
  const b64 = (await toBase64(file)) as string;

  useFileStore.getState().setFile({
    [md5]: { b64, name: fileName, size: file.size },
  });

  return {
    name: fileName,
    md5_hash: md5,
    file,
  };
};

export function computeChecksumMd5(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0; // current cursor in file

    fileReader.onerror = function (): void {
      reject('MD5 computation failed - error reading the file');
    };

    // read chunk starting at `cursor` into memory
    function processChunk(chunk_start: number): void {
      const chunk_end = Math.min(file.size, chunk_start + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
    }

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fileReader.onload = function (e: any): void {
      spark.append(e.target.result); // Accumulate chunk to md5 computation
      cursor += chunkSize; // Move past this chunk

      if (cursor < file.size) {
        // Enqueue next chunk to be accumulated
        processChunk(cursor);
      } else {
        // Computation ended, last chunk has been processed. Return as Promise value.
        // This returns the base64 encoded md5 hash, which is what
        // Rails ActiveStorage or cloud services expect
        resolve(btoa(spark.end(true)));

        // If you prefer the hexdigest form (looking like
        // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
        // resolve(spark.end());
      }
    };

    processChunk(0);
  });
}

export const numberregexp = /^-?\d+(\.\d+)?\.?$|^-$/;

export const accountnumberregexp = /^[0-9]+(-[0-9]*)?$/;

export const ibanregex = /^[a-zA-Z]{1,2}\d*$/;

export const parseFieldValue = <
  TNewValue extends string | null,
  TOldValue extends string | null,
>({
  newValue,
  prevValue,
  regex,
  transformValue,
  commasAsDot = true,
}: {
  newValue: TNewValue;
  prevValue: TOldValue;
  regex: RegExp;
  transformValue?: (value: string) => string;
  commasAsDot?: boolean;
}) => {
  if (!newValue) return null;

  let internal: string = newValue;

  if (commasAsDot && internal.at(-1) === ',') {
    internal = internal.replace(',', '.');
  }

  if (transformValue) {
    internal = transformValue(internal);
  }

  const matches = regex.test(internal);

  if (matches) return internal;

  return prevValue;
};

export const containsOrderedField = <
  TInstance extends BaseInstance = BaseInstance,
  TKey extends string = string,
  TContext extends ReturnType<typeof useFormContext> = ReturnType<
    typeof useFormContext
  >,
>(
  context: TContext
): context is TContext & { orderedFields: OrderedFields<TKey, TInstance> } => {
  if ('orderedFields' in context) return true;
  return false;
};
