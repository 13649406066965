'use client';
import { Sidebar } from '@/components/sidebar';

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <main className='relative flex max-h-window-keeper-height w-screen max-w-[100vw] overflow-hidden'>
      <Sidebar variant='dashboard' />
      <section className='max-h-window-keeper-height w-full overflow-y-auto overflow-x-hidden'>
        {children}
      </section>
    </main>
  );
}
